.List {
  @apply .list-reset .shadow .w-full .border .bg-white rounded;
}

.ListItem {
  @apply .p-4 .border-b .flex .items-center;
}

.ListItem:last-child {
  @apply .border-b-0;
}

.ListItem.draggable {
  @apply .cursor-pointer;
}

.ListItem.draggable:hover {
  @apply .shadow;
}