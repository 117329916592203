/* OSWALD */
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,700&display=swap');
/* PROXIMA NOVA LIGHT */
@font-face {
  font-family: 'proxima-nova';
  src: url('../assets/fonts/proximanova-light-webfont.eot');
  src: url('../assets/fonts/proximanova-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/proximanova-light-webfont.woff2') format('woff2'),
    url('../assets/fonts/proximanova-light-webfont.woff') format('woff'),
    url('../assets/fonts/proximanova-light-webfont.ttf') format('truetype'),
    url('../assets/fonts/proximanova-light-webfont.svg#proxima_novaregular')
      format('svg');
  font-weight: 300;
  font-style: normal;
}
/* PROXIMA NOVA REGULAR */
@font-face {
  font-family: 'proxima-nova';
  src: url('../assets/fonts/proximanova-regular-webfont.eot');
  src: url('../assets/fonts/proximanova-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/proximanova-regular-webfont.woff') format('woff'),
    url('../assets/fonts/proximanova-regular-webfont.ttf') format('truetype'),
    url('../assets/fonts/proximanova-regular-webfont.svg#proxima_novaregular')
      format('svg');
  font-weight: 400;
  font-style: normal;
}
/* PROXIMA NOVA REGULAR ITALIC */
@font-face {
  font-family: 'proxima-nova';
  src: url('../assets/fonts/proximanova-regularit-webfont.eot');
  src: url('../assets/fonts/proximanova-regularit-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/proximanova-regularit-webfont.woff2') format('woff2'),
    url('../assets/fonts/proximanova-regularit-webfont.woff') format('woff'),
    url('../assets/fonts/proximanova-regularit-webfont.ttf') format('truetype'),
    url('../assets/fonts/proximanova-regularit-webfont.svg#proxima_novaregular')
      format('svg');
  font-weight: 400;
  font-style: italic;
}
/* PROXIMA NOVA SEMIBOLD */
@font-face {
  font-family: 'proxima-nova';
  src: url('../assets/fonts/proximanova-semibold-webfont.eot');
  src: url('../assets/fonts/proximanova-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/proximanova-semibold-webfont.woff2') format('woff2'),
    url('../assets/fonts/proximanova-semibold-webfont.woff') format('woff'),
    url('../assets/fonts/proximanova-semibold-webfont.ttf') format('truetype'),
    url('../assets/fonts/proximanova-semibold-webfont.svg#proxima_novaregular')
      format('svg');
  font-weight: 600;
  font-style: normal;
}
/* PROXIMA NOVA OLD */
@font-face {
  font-family: 'proxima-nova';
  src: url('../assets/fonts/proximanova-bold-webfont.eot');
  src: url('../assets/fonts/proximanova-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/proximanova-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/proximanova-bold-webfont.woff') format('woff'),
    url('../assets/fonts/proximanova-bold-webfont.ttf') format('truetype'),
    url('../assets/fonts/proximanova-bold-webfont.svg#proxima_novaregular')
      format('svg');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: Fira Sans, sans-serif;
  /* font-family: 'Oxygen', sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
}

h1 {
  font-weight: 300;
  font-size: 2.25rem;
}

.btn {
  text-transform: uppercase;
  min-width: 200px;
  text-align: center;
}

main {
  /* background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%); */
  background-color: #f9f9f9;
}
nav {
  background-color: #ffffff;
  position: relative;
}

.anchor {
  display: block;
  height: 60px;
  margin-top: -60px;
  visibility: hidden;
}

/* Form Preview - Home Promo */
#preview .preview-homepromo {
  text-transform: uppercase;
  position: relative;
}
#preview .preview-homepromo h3 {
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  line-height: 44px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 12px;
  letter-spacing: 0;
}
#preview .preview-homepromo p {
  font-family: 'proxima-nova', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  letter-spacing: 0;
  color: #fff;
  z-index: 10;
}
#preview .preview-homepromo .ribbon-callout {
  text-transform: capitalize;
  position: absolute;
  width: auto;
  height: 30px;
  top: 20px;
  left: -3px;
  padding: 8px 10px;
  font-family: 'proxima-nova', sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  background: #3490dc;
}
/* force the text to display above the bg_gradient_feature */
.preview-homepromo-text {
  z-index: 1;
}
.bg_gradient_feature {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent linear-gradient(transparent 40%, rgba(0, 0, 0, 0.7))
    repeat scroll 0 0;
}

/* Form Preview - Specials */
#preview .preview-special {
  font-family: 'proxima-nova', sans-serif;
  font-weight: 100;
  background: #fff;
  border-bottom: 1px solid #3490dc;
  position: relative;
  min-height: 70px;
  color: #4a4b4f;
}
#preview .preview-special .tile_text_box {
  position: relative;
  padding: 45px 40px;
}
#preview .preview-special h3.headline {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  margin-bottom: 20px;
  color: #222;
}
#preview .preview-special .content {
  font-family: 'proxima-nova', sans-serif;
  font-size: 15px;
}
#preview .preview-special .content p {
  line-height: 24px;
  color: #4a4b4f;
  margin-bottom: 20px;
}
#preview .preview-special .content ul {
  margin-bottom: 20px;
}
#preview .preview-special .content li {
  color: #4a4b4f;
  line-height: 24px;
}
#preview .preview-special .content a {
  color: #4a4b4f;
  font-weight: 600;
  border-bottom: 0;
  text-decoration: none;
}
#preview .preview-special .content a:hover {
  border-bottom: 1px solid inherit;
  text-decoration: underline;
}
#preview .preview-special .ribbon-callout {
  text-transform: capitalize;
  position: absolute;
  width: auto;
  height: 30px;
  top: 20px;
  left: -3px;
  padding: 8px 10px;
  font-family: 'proxima-nova', sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  background: #3490dc;
}
#preview .preview-special .tile_action_link {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  border-bottom: 0;
  border-top: 1px solid #e3e3e3;
  color: #15151a;
  display: block;
  font-family: 'Oswald', serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.04em;
  line-height: 1.2;
  padding: 14px 20px;
  text-transform: uppercase;
}

/* Form Preview - Meetings, Weddings, Private Dining and Misc Tiled Promos */
.restrictions {
  margin-bottom: 4em;
}
#preview .preview-special .restrictions p {
  font-size: 12px;
  line-height: 14px;
  color: #15151a;
  text-transform: uppercase;
  font-weight: 400;
  border-bottom: 0;
}
#preview .preview-special .restrictions a {
  color: #222222;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 0;
  text-decoration: none;
}
#preview .preview-special .restrictions a:hover {
  border-bottom: 1px solid #222222;
}
#preview .preview-special .button_link_bottom {
  position: absolute;
  bottom: 0;
  padding: 10px 40px 30px;
  width: 100%;
  margin-bottom: 0;
  text-align: left;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16px;
  border-bottom-color: #222222;
  font-weight: 600;
  font-family: 'proxima-nova', sans-serif;
}

/*
Need to load the icomoon icons for the Book Now calendar icon for the Tiled Promos preview
Hiding it for now.
*/
/* #preview .preview-special .tile_action_link:before{
  -moz-osx-font-smoothing: grayscale;
  bottom: 2px;
  color: #c1c5cc;
  float: right;
  font-family: "icomoon";
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  position: relative;
  text-transform: none;
  transition: color 0.13s ease-in-out 0s;
  content: "\e60e";
} */

/* Breadcrumb */
.breadcrumb {
  background: #f2f2f2;
  color: #888888;
}
.breadcrumb a {
  color: #888888;
}
.breadcrumb span {
  color: #444444;
}

/* Body */
#body {
  color: #555555;
}

/* Form Sections Navigator */
.formnavigator > .uppercase {
  letter-spacing: 2px;
}

/* Alerts */
.global-alert {
  border: 1px solid #ccc;
  border-radius: 0.5em;
  padding: 2em 1em;
  margin-bottom: 3em;
  overflow: hidden;
}
.alerts_tabs {
  display: none;
}

/* FAQs preview */
.faq-question-wrapper {
  font-family: 'proxima-nova', sans-serif;
  font-size: 18px;
  color: #53555b;
  line-height: 32px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.faq-question-wrapper h4 {
  font-weight: 600;
}
.faq-question-wrapper p {
  line-height: 32px;
}
.faq-question-wrapper a {
  font-weight: 600;
  color: #53555b;
  text-decoration: none;
  border-bottom: 1px solid #3490dc;
}
.faq-question-wrapper a:hover {
  border-bottom: none;
}
.faq-question-wrapper a:visited {
  color: #53555b;
}

/* View Live Page link */
a#hotel-promos-link-link,
a#hotel-special-link-link,
a#hotel-meetings-link-link,
a#hotel-weddings-link-link,
a#hotel-dining-link-link,
a#hotel-misc-link-link,
a#hotel-accom-link-link,
a#hotel-faqs-link-link {
  color: #3490dc;
  text-decoration: none;
}
a#hotel-special-link-link:hover,
a#hotel-meetings-link-link:hover,
a#hotel-promos-link-link:hover,
a#hotel-weddings-link-link:hover,
a#hotel-dining-link-link:hover,
a#hotel-misc-link-link:hover,
a#hotel-accom-link-link:hover,
a#hotel-faqs-link-link:hover {
  text-decoration: underline;
}

/* Fixes the Search results bar in the header */
#header-search-menu {
  position: absolute;
  width: 228px;
  height: auto;
  max-height: 320px;
}
@media screen and (max-width: 1032px) {
  #header-search-menu {
    height: auto;
    max-height: 320px;
    width: 186px;
  }
}

/* Let last column overflow be visible so More Menu would show */
.ReactTable .rt-tbody .rt-td:last-child {
  overflow: visible;
}

/* keep main nav persistently above all content on mobile and tablet */
#mainnav {
  z-index: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  height: 100%;
  overflow: scroll;
  padding-bottom: 62px;
}

/* Adds up arrow to the "More" options tooltip for table rows */
.arrow-up {
  position: absolute;
  width: 0;
  height: 0;
  top: -12px;
  right: 12px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #dae1e7;
}

/* Pagination styling */
.paginate {
  display: block;
  color: #7b8892;
  text-transform: none;
  line-height: 43px;
  margin-left: auto;
  text-align: right;
}
.paginate span {
  white-space: nowrap;
}
.paginate__controls button {
  color: #2779bd;
}
.paginate__controls button:hover {
  text-decoration: underline;
}
/* Hide the pagination controls on mobile, show only on displays 800px and above */
.react-tabs .paginate {
  display: none;
}
@media screen and (min-width: 800px) {
  .react-tabs .paginate {
    display: block;
  }
}

.list-page-display-on {
  white-space: pre-wrap;
}

/* Property Dashboard Styling */
.dashboard-tile a:not(.btn),
#dashboard-links a {
  color: #3490dc;
  text-decoration: none;
}
.dashboard-tile a:hover:not(.btn),
#dashboard-links a:hover {
  text-decoration: underline;
}
#active-specials-tile {
  flex-wrap: wrap;
}
#active-specials-tile > div {
  flex-basis: 16.6%;
}
.dashboard-three-tile > .content > div {
  flex-basis: 32%;
  background-color: white;
}
.dashboard-three-tile .btn {
  min-width: 100px;
}
/* Hours Table Dropdowns */
#meal-type-button,
#start-days-button,
#end-days-button,
#start-hour-type-button,
#end-hour-type-button {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #333333;
}
#meal-type-button > .border-l.border-grey,
#start-days-button > .border-l.border-grey,
#end-days-button > .border-l.border-grey,
#start-hour-type-button > .border-l.border-grey,
#end-hour-type-button > .border-l.border-grey {
  border-left: none;
}

.\!no-underline {
  text-decoration: none !important;
}

.rbc-event .event--unpublished {
  color: #aaa;
}
