.ReactTable {
  @apply .rounded-t .shadow .bg-white;
}

.ReactTable .rt-thead.-header {
  @apply .bg-grey-lighter .shadow-none .border-b;
  border-color: rgba(0, 0, 0, 0.1);
}

.ReactTable .rt-tbody .rt-tr {
  @apply .text-left .pt-2 .pb-2;
}

.ReactTable .rt-tbody .rt-tr-group.draggable {
  @apply .cursor-pointer;
}

.ReactTable .rt-tbody .rt-tr-group.draggable:hover {
  @apply .shadow;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  @apply .border-0 .py-4 .px-2 .text-left .font-normal text-grey-darkest;
}

.ReactTable .rt-thead .rt-th:first-child,
.ReactTable .rt-thead .rt-td:first-child {
  @apply .pl-4;
}

.ReactTable .rt-thead .rt-th:first-child,
.ReactTable .rt-thead .rt-td:last-child {
  @apply .pr-4;
}

.ReactTable .rt-tbody .rt-td {
  @apply .border-r-0 .py-3 .px-2 .font-light .text-grey-darker;
}

.ReactTable .rt-tbody .rt-td a {
  @apply .text-blue-dark;
  text-decoration: none;
}
.ReactTable .rt-tbody .rt-td a:hover {
  text-decoration: underline;
}

.ReactTable .rt-tbody .rt-td:first-child {
  @apply .pl-4;
}

.ReactTable .rt-tbody .rt-td:last-child {
  @apply .pr-4;
}

/* Tables for Clone and New page templates */
.ListItem a,
.list-reset a {
  @apply .text-blue-dark;
  text-decoration: none;
  font-weight: 300;
}
.ListItem a:hover,
.list-reset a:hover {
  text-decoration: underline;
}

/* Alerts Table, fix word wrapping for narrow columns */
.ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: normal;
}
.ReactTable .rt-tbody .rt-td {
  word-wrap: break-word;
}

/* Tables scroll horizontal on devices 767px and below */
@media screen and (max-width: 767px) {
  .ReactTable {
    width: 94vw;
    overflow-x: auto;
  }
  .ReactTable .rt-tbody {
    min-height: 200px;
  }
  .ReactTable .rt-tr { }
  .ReactTable .rt-td { }
}
@media screen and (max-width: 500px) {
  .ReactTable {
    width: 92vw;
  }
}
