.react-tabs__tab-list {
  @apply .border-none .flex .m-0;
}

.react-tabs__tab {
  @apply .border-0 .border-transparent .text-grey .flex .py-3 .px-3;
  bottom: 0px;
  border-bottom-style: solid;
  text-transform: uppercase;
}

.react-tabs__tab:focus {
  @apply .shadow-none .border-blue-light .tracking-wide;
  border-bottom-style: solid;
}

.react-tabs__tab:focus:after {
  content: none;
}

.react-tabs__tab--selected {
  @apply .border-blue-light .text-blue-light .bg-transparent .border-b-2;
}

.dashboard-tile .react-tabs__tab--selected {
  @apply .border-blue-500 .text-black .bg-transparent .border-b-3;
}
.react-tabs__tab-pill {
  @apply .px-2 .py-1 .rounded-full .bg-grey-light .text-white .flex .justify-center .font-normal .tracking-wide;
  font-size: 10px;
  width: 36px;
}

.react-tabs__tab--selected .react-tabs__tab-pill {
  @apply .bg-blue-light;
}
