.activeHomePromoPreview {
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  display: grid;
  grid-column-gap: 2%;
  grid-row-gap: 4%;
  height: 440px;
  width: 80%;
}
.activeHomePromoPreview .preview-homepromo {
  background-repeat: no-repeat;
  background-size: cover;
}
.activeHomePromoPreview .preview-homepromo {
  width: 100%;
  height: 100%;
}
.activeHomePromoPreview .preview-homepromo:first-child {
  grid-column: 1/2;
  grid-row: 1/3;
}
.activeHomePromoPreview .preview-homepromo:nth-child(2) {
  grid-column: 2/3;
  grid-row: 1/2;
}
.activeHomePromoPreview .preview-homepromo:nth-child(3) {
  grid-column: 2/3;
  grid-row: 2/3;
}
#preview.activeHomePromoPreview .preview-homepromo h3 {
  font-size: 20px;
  line-height: 26px;
  display: inline;
}
#preview.activeHomePromoPreview .preview-homepromo h3 p {
  font-size: 20px;
  line-height: 26px;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}
#preview.activeHomePromoPreview .preview-homepromo p {
  font-size: 11px;
  line-height: 13px;
  display: inline;
}
span.arrow-link:after {
  content: ' >';
  width: 0;
  display: inline-block;
  margin-left: 4px;
}
@media only screen and (max-width: 1120px) {
  .activeHomePromoPreview {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    grid-column-gap: 2%;
    grid-row-gap: 3%;
    width: 70%;
    height: 550px;
  }
  .activeHomePromoPreview .preview-homepromo:first-child {
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .activeHomePromoPreview .preview-homepromo:nth-child(2) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .activeHomePromoPreview .preview-homepromo:nth-child(3) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}
@media only screen and (max-width: 768px) {
  .activeHomePromoPreview {
    grid-template-columns: 100%;
    grid-template-rows: 33% 33% 33%;
    grid-column-gap: 0;
    grid-row-gap: 1%;
    height: 600px;
  }
  .activeHomePromoPreview .preview-homepromo:first-child {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .activeHomePromoPreview .preview-homepromo:nth-child(2) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .activeHomePromoPreview .preview-homepromo:nth-child(3) {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}

@media only screen and (max-width: 650px) {
  .activeHomePromoPreview {
    display: none;
  }
  #homepromos-preview-header {
    display: none;
  }
  .preview-note {
    display: none;
  }
}
