/* eslint-disable-line */
.btn {
  @apply .bg-blue .font-bold .px-16 .py-4 .text-white .border-2 .border-blue .rounded .block .p-4;
  transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
}

.btn:hover {
  @apply .bg-blue-light .border-blue-light .shadow-md;
}

.btn:focus {
  @apply .outline-none;
}

.btn-link {
  @apply .bg-transparent .border-none .text-black .underline .px-0;
}

.btn-link:hover {
  @apply .bg-transparent .border-none;
}

.btn-clear {
  @apply .bg-transparent .text-grey-darker .border-grey-dark;
}

.btn-clear:hover {
  @apply .bg-transparent .border-grey-darker .shadow-md .bg-white;
}

.btn:disabled {
  @apply .bg-grey-light .text-grey-dark .font-semibold .border-grey-light .cursor-not-allowed;
}

.btn-red {
  @apply .bg-red-dark .border-red-dark;
}

.btn-red:hover {
  @apply .bg-red-darker;
}

@media all and (min-width: 768px) and (max-width: 879px) {
  .btn.forgot-pw-cancel {
    margin-right: 0;
  }
}
