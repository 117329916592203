header {
  /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(10,174,227,1) 0%, rgba(124,182,255,1) 100.2% ); */
  /* background-image: linear-gradient(to right, #000046 0%, #1CB5E0 51%) */
  /* background-image: linear-gradient(to right, #4B79A1 0%, #283E51 51%); */
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

/* Logo */
.logo-kimpton {
  display: block;
  background: url('../../assets/kimpton-logo-black.png') no-repeat;
  background-size: contain;
  width: 62px;
  margin-top: 19px;
  margin-left: 0px;
  text-indent: -9999px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .logo-kimpton {
    width: 76px;
    margin-top: 16px;
  }
}
@media (min-width: 1024px) {
  .logo-kimpton {
    height: 30px;
    width: 105px;
    margin-top: 6px;
  }
}

ul.nav-list-item li {
  @apply .text-base;
}
@media all and (max-width: 499px) {
  ul.nav-list-item li {
    @apply .text-xs;
  }
}

@media all and (max-width: 1023px) {
  #header-search {
    @apply .text-xs;
  }
}
svg#nav-icon path {
  fill: red!important
}
