.rbc-calendar {
	min-height: 580px;
}
.rbc-month-row {
	flex-basis: auto;
	min-height: 100px;
}
.rbc-event {
	background-color: transparent;
	color: #3490dc;
}
.event-label:hover .event__title {
	text-decoration: underline;
}
.rbc-date-cell {
	text-align: left;
	padding-left: 5px;
}
.event-label__time {
	white-space: normal;
}
.event__title {
	white-space: normal;
}
