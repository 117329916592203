.Input {
  @apply .border .border-grey .font-light .px-2 .py-4 .rounded-sm;
}
.Input-restaurant-hours {
  @apply .border .border-grey .font-light .py-4 .rounded-sm;
}

.Input--error {
  @apply .border-red;
}

.Input[disabled] {
  background-color: #ddd;
  cursor: not-allowed;
}
